@charset "UTF-8";

/*!
	!!!!!!!!!!!!!!!!!!!!!!!! 削除禁止 開始 !!!!!!!!!!!!!!!!!!!!!!!!
	ページ別のCSSを読み込ませる
*/
@import 'pages/pageID-contents-greeninfra'; // グリーンインフラ特集
@import 'pages/pageID-original-sekisan'; // 特集：東京都の積算
/*!
	!!!!!!!!!!!!!!!!!!!!!!!! 削除禁止 終了 !!!!!!!!!!!!!!!!!!!!!!!!
*/


/*!
 * ！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！
 *
 * ユーザー専用スタイルシート
 *
 * assets内直下の、js、styles内のファイルを編集することは厳禁なので、
 * 新規スタイル追加、及び既存スタイルに対して追記スタイルがある場合はこちらに記載をお願いいたします。
 *
 * ！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！
 */

.sampleCss {
	color: red;
	font-size: 24px;
	background-color: #f0c;
}
@media screen and (max-width:736px){
	.sampleCss {
		font-size: 14px;
	}
}



/*
	.pageID-xxxxxxxxxxxx
	ページ名
------------------------------------------------------------------------ */
.pageID-xxxxxxxxxxxx {
}
@media screen and (max-width: 735px) {
}
@media screen and (max-width: 735px + 1){
}
