@charset "UTF-8";

/*
	.pageID-contents-greeninfra
	グリーンインフラ特集
------------------------------------------------------------------------ */
.pageID-contents-greeninfra {
	$greeninfra-color01: #04a167;
	$greeninfra-color02: #487cc2;
	$greeninfra-color03: #c2485f;
	$greeninfra-color04: #c26c26;
	$greeninfra-color05: #dbcd2e;
	$greeninfra-color06: #04a167;
	$greeninfra-color07: #56cea2;
	$greeninfra-color08: #487cc2;
	$greeninfra-color09: #56cea2;
	$greeninfra-color10: #a0655d;
	$greeninfra-color11: #B1A400;

	.greeninfra-menu {
		$this: '.greeninfra-menu';
		display: grid;
		place-content: center;
		grid-template-columns: repeat(6, 1fr);
		gap: 20px;
		// display: flex;
		// align-items: center;
		// justify-content: center;
		// gap: 5px;
		// & > * {
		// 	display: block;
		// 	text-align: center;
		// 	flex: 1;
		// }
		&:not(:first-child) {
			margin-top: 30px;
		}
	}
	.greeninfra-menuItem {
		display: grid;
		place-content: center;
		justify-content: center;
		// gap: 10px;

		position: relative;
		padding: 8px;
		text-align: center;
		text-decoration: none;
		min-height: 150px;
		border-radius: 3px;
		// background-color: #fefefe;
		-webkit-box-shadow: 0 0 4px 0 rgba(#ccc , .7);
						box-shadow: 0 0 4px 0 rgba(#ccc , .7);
		&:hover {
			opacity: 1;
			-webkit-box-shadow: 0 0 4px 0 rgba(#bebebe , .9);
							box-shadow: 0 0 4px 0 rgba(#bebebe , .9);
			.greeninfra-menuBg {
				opacity: 1;
			}
		}
		&-color01 {
			color: $greeninfra-color01;
		}
		&-color02 {
			color: $greeninfra-color02;
		}
		&-color03 {
			color: $greeninfra-color03;
		}
		&-color04 {
			color: $greeninfra-color11;
			.greeninfra-menuBg {
				img {
					opacity: 0.6;
				}
			}
		}
		&-color05 {
			color: $greeninfra-color06;
		}
		&-color06 {
			color: $greeninfra-color10;
		}
	}
	.greeninfra-menuTitles {
		display: grid;
		place-content: center;
		justify-content: center;
		gap: 10px;

		position: relative;
		font-size: 18px;
		font-weight: 700;
		z-index: 5;
		small {
			display: block;
			font-size: 11px;
		}
	}
	.greeninfra-menuBg {
		display: flex;
		align-items: center;
		justify-content: center;

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: .3s;
		img {
			width: 80%;
			height: 80%;
			object-fit: cover;
			opacity: 0.3;
		}
	}

	.greeninfra-leadFrame {
		$this: '.greeninfra-leadFrame';
		width: 80%;
		margin-left: auto;
		margin-right: auto;
		font-size: 16px;
		&:not(:first-child) {
			margin-top: 80px;
		}
		strong {
			font-size: 18px;
			line-height: 1.85;
		}
	}

	.greeninfra-section {
		$this: '.greeninfra-section';
		&:not(:first-child) {
			margin-top: 140px;
		}
		&-firstSection {
			&:not(:first-child) {
				margin-top: 80px;
			}
		}
	}

	.greeninfra-article {
		$this: '.greeninfra-article';
		&:not(:first-child) {
			margin-top: 65px;
		}
	}

	.greeninfra-figure {
		margin: 0;
	}

	.greeninfra-subFigure {
		&:not(:first-child) {
			margin-top: 25px;
		}
	}

	.greeninfra-btns {
		&:not(:first-child) {
			margin-top: 10px;
		}
	}

	.greeninfra-headLine01 {
		$this: '.greeninfra-headLine01';
		text-align: center;
	}

	.greeninfra-headLine02 {
		$this: '.greeninfra-headLine02';
		margin-bottom: 20px;
		border-left: 10px solid #555555;
		border-bottom: 2px solid #555555;
		padding: 0 0.3em;
		&-color01 {
			border-color: $greeninfra-color01;
		}
		&-color02 {
			border-color: $greeninfra-color02;
		}
		&-color03 {
			border-color: $greeninfra-color03;
		}
		&-color04 {
			border-color: $greeninfra-color04;
		}
		&-color05 {
			border-color: $greeninfra-color05;
		}
		&-color06 {
			border-color: $greeninfra-color06;
		}
		&-color07 {
			border-color: $greeninfra-color07;
		}
		&-color08 {
			border-color: $greeninfra-color08;
		}
		&-color09 {
			border-color: $greeninfra-color09;
		}
		&-color10 {
			border-color: $greeninfra-color10;
		}
	}

	.gridColumn {
		$this: '.gridColumn';
		margin-top: 0;
	}
}
@media screen and (max-width: 735px) {
	.pageID-contents-greeninfra {
		.gridColumn {
			$this: '.gridColumn';
			&-mainContents {
				#{$this}_col {
					&:nth-child(odd) {
						text-align: left;
					}
					&:nth-child(even) {
						text-align: right;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 735px + 1){
	.pageID-contents-greeninfra {
		margin-top: 10px;

		.greeninfra-headLine01 {
			$this: '.greeninfra-headLine01';
		}

		.greeninfra-menu {
			$this: '.greeninfra-menu';
			grid-template-columns: repeat(2, 1fr);
			gap: 15px;
			&:not(:first-child) {
				margin-top: 30px;
			}
		}
		.greeninfra-menuItem {
			// box-shadow: inset 0 0 0 2px #3ed !important;
			min-height: 90px;
		}
		.greeninfra-menuTitles {
			font-size: 14px;
			small {
				font-size: 10px;
			}
		}
		.greeninfra-menuBg {
			img {
				width: auto;
				height: 90%;
				object-fit: contain;
			}
		}

		.greeninfra-leadFrame {
			$this: '.greeninfra-leadFrame';
			width: 85%;
			font-size: 16px;
			&:not(:first-child) {
				margin-top: 50px;
			}
			strong {
				font-size: 16px;
				line-height: 1.75;
			}
		}

		.greeninfra-section {
			$this: '.greeninfra-section';
			&:not(:first-child) {
				margin-top: 70px;
			}
			&-firstSection {
				&:not(:first-child) {
					margin-top: 50px;
				}
			}
		}

		.greeninfra-article {
			$this: '.greeninfra-article';
			&:not(:first-child) {
				margin-top: 45px;
			}
		}

		.greeninfra-subFigure {
			&:not(:first-child) {
				margin-top: 20px;
			}
		}

		.greeninfra-btns {
			text-align: center;
			&:not(:first-child) {
				margin-top: 18px;
			}
		}

		.gridColumn {
			$this: '.gridColumn';
			&_col {
				text-align: center;
				&:not(:first-child) {
					margin-top: 10px;
				}
			}
			&-mainContents {
				#{$this}_col {
					&:nth-child(odd) {
					}
					&:nth-child(even) {
						 order: -1;
					}
				}
			}
		}

		#greeninfra05-02 ,
		#greeninfra04-02 ,
		#greeninfra03-02 {
			.gridColumn {
				$this: '.gridColumn';
				&-mainContents {
					#{$this}_col {
						&:nth-child(odd) {
							order: -1;
						}
					}
				}
			}
		}
	}
}
