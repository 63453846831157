@charset "UTF-8";
/*!
	!!!!!!!!!!!!!!!!!!!!!!!! 削除禁止 開始 !!!!!!!!!!!!!!!!!!!!!!!!
	ページ別のCSSを読み込ませる
*/
/*
	.pageID-contents-greeninfra
	グリーンインフラ特集
------------------------------------------------------------------------ */
.pageID-contents-greeninfra .greeninfra-menu {
  display: grid;
  place-content: center;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
}

.pageID-contents-greeninfra .greeninfra-menu:not(:first-child) {
  margin-top: 30px;
}

.pageID-contents-greeninfra .greeninfra-menuItem {
  display: grid;
  place-content: center;
  justify-content: center;
  position: relative;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  min-height: 150px;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 4px 0 rgba(204, 204, 204, 0.7);
  box-shadow: 0 0 4px 0 rgba(204, 204, 204, 0.7);
}

.pageID-contents-greeninfra .greeninfra-menuItem:hover {
  opacity: 1;
  -webkit-box-shadow: 0 0 4px 0 rgba(190, 190, 190, 0.9);
  box-shadow: 0 0 4px 0 rgba(190, 190, 190, 0.9);
}

.pageID-contents-greeninfra .greeninfra-menuItem:hover .greeninfra-menuBg {
  opacity: 1;
}

.pageID-contents-greeninfra .greeninfra-menuItem-color01 {
  color: #04a167;
}

.pageID-contents-greeninfra .greeninfra-menuItem-color02 {
  color: #487cc2;
}

.pageID-contents-greeninfra .greeninfra-menuItem-color03 {
  color: #c2485f;
}

.pageID-contents-greeninfra .greeninfra-menuItem-color04 {
  color: #B1A400;
}

.pageID-contents-greeninfra .greeninfra-menuItem-color04 .greeninfra-menuBg img {
  opacity: 0.6;
}

.pageID-contents-greeninfra .greeninfra-menuItem-color05 {
  color: #04a167;
}

.pageID-contents-greeninfra .greeninfra-menuItem-color06 {
  color: #a0655d;
}

.pageID-contents-greeninfra .greeninfra-menuTitles {
  display: grid;
  place-content: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  font-size: 18px;
  font-weight: 700;
  z-index: 5;
}

.pageID-contents-greeninfra .greeninfra-menuTitles small {
  display: block;
  font-size: 11px;
}

.pageID-contents-greeninfra .greeninfra-menuBg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: .3s;
}

.pageID-contents-greeninfra .greeninfra-menuBg img {
  width: 80%;
  height: 80%;
  object-fit: cover;
  opacity: 0.3;
}

.pageID-contents-greeninfra .greeninfra-leadFrame {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
}

.pageID-contents-greeninfra .greeninfra-leadFrame:not(:first-child) {
  margin-top: 80px;
}

.pageID-contents-greeninfra .greeninfra-leadFrame strong {
  font-size: 18px;
  line-height: 1.85;
}

.pageID-contents-greeninfra .greeninfra-section:not(:first-child) {
  margin-top: 140px;
}

.pageID-contents-greeninfra .greeninfra-section-firstSection:not(:first-child) {
  margin-top: 80px;
}

.pageID-contents-greeninfra .greeninfra-article:not(:first-child) {
  margin-top: 65px;
}

.pageID-contents-greeninfra .greeninfra-figure {
  margin: 0;
}

.pageID-contents-greeninfra .greeninfra-subFigure:not(:first-child) {
  margin-top: 25px;
}

.pageID-contents-greeninfra .greeninfra-btns:not(:first-child) {
  margin-top: 10px;
}

.pageID-contents-greeninfra .greeninfra-headLine01 {
  text-align: center;
}

.pageID-contents-greeninfra .greeninfra-headLine02 {
  margin-bottom: 20px;
  border-left: 10px solid #555555;
  border-bottom: 2px solid #555555;
  padding: 0 0.3em;
}

.pageID-contents-greeninfra .greeninfra-headLine02-color01 {
  border-color: #04a167;
}

.pageID-contents-greeninfra .greeninfra-headLine02-color02 {
  border-color: #487cc2;
}

.pageID-contents-greeninfra .greeninfra-headLine02-color03 {
  border-color: #c2485f;
}

.pageID-contents-greeninfra .greeninfra-headLine02-color04 {
  border-color: #c26c26;
}

.pageID-contents-greeninfra .greeninfra-headLine02-color05 {
  border-color: #dbcd2e;
}

.pageID-contents-greeninfra .greeninfra-headLine02-color06 {
  border-color: #04a167;
}

.pageID-contents-greeninfra .greeninfra-headLine02-color07 {
  border-color: #56cea2;
}

.pageID-contents-greeninfra .greeninfra-headLine02-color08 {
  border-color: #487cc2;
}

.pageID-contents-greeninfra .greeninfra-headLine02-color09 {
  border-color: #56cea2;
}

.pageID-contents-greeninfra .greeninfra-headLine02-color10 {
  border-color: #a0655d;
}

.pageID-contents-greeninfra .gridColumn {
  margin-top: 0;
}

@media screen and (max-width: 735px) {
  .pageID-contents-greeninfra .gridColumn-mainContents .gridColumn_col:nth-child(odd) {
    text-align: left;
  }
  .pageID-contents-greeninfra .gridColumn-mainContents .gridColumn_col:nth-child(even) {
    text-align: right;
  }
}

@media screen and (max-width: 736px) {
  .pageID-contents-greeninfra {
    margin-top: 10px;
  }
  .pageID-contents-greeninfra .greeninfra-menu {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  .pageID-contents-greeninfra .greeninfra-menu:not(:first-child) {
    margin-top: 30px;
  }
  .pageID-contents-greeninfra .greeninfra-menuItem {
    min-height: 90px;
  }
  .pageID-contents-greeninfra .greeninfra-menuTitles {
    font-size: 14px;
  }
  .pageID-contents-greeninfra .greeninfra-menuTitles small {
    font-size: 10px;
  }
  .pageID-contents-greeninfra .greeninfra-menuBg img {
    width: auto;
    height: 90%;
    object-fit: contain;
  }
  .pageID-contents-greeninfra .greeninfra-leadFrame {
    width: 85%;
    font-size: 16px;
  }
  .pageID-contents-greeninfra .greeninfra-leadFrame:not(:first-child) {
    margin-top: 50px;
  }
  .pageID-contents-greeninfra .greeninfra-leadFrame strong {
    font-size: 16px;
    line-height: 1.75;
  }
  .pageID-contents-greeninfra .greeninfra-section:not(:first-child) {
    margin-top: 70px;
  }
  .pageID-contents-greeninfra .greeninfra-section-firstSection:not(:first-child) {
    margin-top: 50px;
  }
  .pageID-contents-greeninfra .greeninfra-article:not(:first-child) {
    margin-top: 45px;
  }
  .pageID-contents-greeninfra .greeninfra-subFigure:not(:first-child) {
    margin-top: 20px;
  }
  .pageID-contents-greeninfra .greeninfra-btns {
    text-align: center;
  }
  .pageID-contents-greeninfra .greeninfra-btns:not(:first-child) {
    margin-top: 18px;
  }
  .pageID-contents-greeninfra .gridColumn_col {
    text-align: center;
  }
  .pageID-contents-greeninfra .gridColumn_col:not(:first-child) {
    margin-top: 10px;
  }
  .pageID-contents-greeninfra .gridColumn-mainContents .gridColumn_col:nth-child(even) {
    order: -1;
  }
  .pageID-contents-greeninfra #greeninfra05-02 .gridColumn-mainContents .gridColumn_col:nth-child(odd),
  .pageID-contents-greeninfra #greeninfra04-02 .gridColumn-mainContents .gridColumn_col:nth-child(odd),
  .pageID-contents-greeninfra #greeninfra03-02 .gridColumn-mainContents .gridColumn_col:nth-child(odd) {
    order: -1;
  }
}

/*
	.pageID-original-sekisan
	特集：東京都の積算
------------------------------------------------------------------------ */
.pageID-original-sekisan {
  line-height: 1.85;
  /*
	.sekisan-headLine
	<div class="sekisan-headLine">
		<div class="sekisan-headLine_header">
			<figure class="sekisan-headLine_icon">
				<img src="/files/sekisan/icon.jpg" width="60" height="60">
			</figure>
			<span class="sekisan-headLine_sub">PR</span>
			<h3 class="sekisan-headLine_title">積算とは？</h3>
		</div>
		<div class="sekisan-headLine_side">
			<div class="sekisan-headLine_btns">
				<a class="sekisan-btn" href="/blog/blog_detail.php?id=512">
					<span>詳しくみる　●</span>
				</a>
			</div>
		</div>
	</div><!-- /.sekisan-headLine -->
	-------------------------------------- */
  /*
	.sekisan-btn
	<a class="sekisan-btn" href="xxxxxx">
		<span>詳しくみる　●</span>
	</a>
	-------------------------------------- */
}

.pageID-original-sekisan .sekisan-headLine {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2.5em;
}

.pageID-original-sekisan .sekisan-headLine > * {
  margin: 0;
}

.pageID-original-sekisan .sekisan-headLine_header {
  display: flex;
  align-items: center;
  gap: 2.5em;
}

.pageID-original-sekisan .sekisan-headLine_header > * {
  margin: 0;
}

.pageID-original-sekisan .sekisan-headLine_icon {
  flex: 0 0 auto;
}

.pageID-original-sekisan .sekisan-headLine_sub {
  flex: 0 0 auto;
  border: solid 1px;
  border-radius: 10px;
  padding: 8px 15px 6px;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.2;
}

.pageID-original-sekisan .sekisan-headLine_title {
  font-size: 40px;
  font-weight: bold;
  line-height: 1.5;
}

.pageID-original-sekisan .sekisan-headLine_btns {
  text-align: right;
}

.pageID-original-sekisan .sekisan-headLine-small {
  gap: 1.8em;
}

.pageID-original-sekisan .sekisan-headLine-small .sekisan-headLine_header {
  gap: 1.8em;
}

.pageID-original-sekisan .sekisan-headLine-small .sekisan-headLine_title {
  font-size: 26px;
}

.pageID-original-sekisan .sekisan-headLine-underLine {
  padding-bottom: 10px;
  border-bottom: solid #000000 1px;
}

.pageID-original-sekisan .sekisan-btn {
  display: inline-block;
  width: fit-content;
  padding: 12px 50px;
  text-decoration: none;
  font-size: 18px;
  border: solid 1px;
  background: #ffffff;
  border-radius: calc(1px / 0);
}

.pageID-original-sekisan .sekisan-btn:hover {
  opacity: 1;
  color: #fff;
  background-color: #253f68;
}

.pageID-original-sekisan .sekisan-btn-small {
  padding: 5px 25px 3px;
  font-size: 14px;
}

.pageID-original-sekisan .sekisan-mainVisual {
  background: #b8d7da;
}

.pageID-original-sekisan .sekisan-mainVisual_mv {
  text-align: center;
}

.pageID-original-sekisan .sekisan-mainVisual_contents {
  width: 75%;
  margin: 20px auto 0;
}

.pageID-original-sekisan .sekisan-mainVisual_headLine {
  font-size: 40px;
  font-weight: bold;
  border-bottom: solid 1px;
}

.pageID-original-sekisan .sekisan-mainVisual_txt {
  font-size: 19px;
  margin-bottom: 18px;
}

.pageID-original-sekisan .sekisan-mainVisual_lower {
  margin: 0;
  padding-top: 100px;
  padding-bottom: 100px;
}

.pageID-original-sekisan .sekisan-mainVisual_lower-txt {
  font-size: 19px;
}

.pageID-original-sekisan .sekisan-section:not(:first-child) {
  margin-top: 100px;
}

.pageID-original-sekisan .sekisan-contribute {
  background: #93959d;
}

.pageID-original-sekisan .sekisan-contribute_inner {
  display: grid;
  place-content: center;
  grid-template-columns: minmax(auto, 460px) 1fr;
}

.pageID-original-sekisan .sekisan-contribute_side {
  position: relative;
}

.pageID-original-sekisan .sekisan-contribute_figure {
  margin: 0;
}

.pageID-original-sekisan .sekisan-contribute_sideFrame {
  display: inline-block;
  position: absolute;
  text-align: center;
  font-size: 19px;
  font-weight: bold;
  top: 18%;
  right: 5%;
}

.pageID-original-sekisan .sekisan-contribute_subTitle {
  border: solid 1px;
  border-radius: 10px;
  padding: 5px 15px;
}

.pageID-original-sekisan .sekisan-contribute_postName {
  margin: 0;
}

.pageID-original-sekisan .sekisan-contribute_main {
  align-self: center;
  margin: 0 auto;
  text-align: center;
  font-size: 19px;
  color: #ffffff;
}

.pageID-original-sekisan .sekisan-contribute_headLine {
  display: grid;
  grid-template-rows: auto;
  gap: 5px;
  margin: 0;
  line-height: 1.45;
}

.pageID-original-sekisan .sekisan-contribute_headLine small {
  font-size: 19px;
}

.pageID-original-sekisan .sekisan-contribute_headLine strong {
  font-size: 36px;
  font-weight: bold;
}

.pageID-original-sekisan .sekisan-contribute_btns {
  margin: 0;
}

.pageID-original-sekisan .sekisan-contribute_btns:not(:first-child) {
  margin-top: 18px;
}

.pageID-original-sekisan .sekisan-symposium {
  width: 75%;
  margin: 0 auto;
}

.pageID-original-sekisan .sekisan-symposium_contents:not(:first-child) {
  margin-top: 38px;
}

.pageID-original-sekisan .sekisan-symposium_figure {
  text-align: center;
}

.pageID-original-sekisan .sekisan-symposium_title {
  font-size: 40px;
  text-align: center;
}

.pageID-original-sekisan .sekisan-symposium_title:not(:first-child) {
  margin-top: 20px;
}

.pageID-original-sekisan .sekisan-symposium_txt {
  font-size: 19px;
  margin-bottom: 20px;
}

.pageID-original-sekisan .sekisan-symposium_btns {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 60px;
}

.pageID-original-sekisan .sekisan-symposium_toc {
  display: grid;
  place-content: center;
  justify-items: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
}

.pageID-original-sekisan .sekisan-symposium_tocCol {
  font-size: 26px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  font-weight: bold;
}

.pageID-original-sekisan .sekisan-estimationm {
  background: #b8d7da;
}

.pageID-original-sekisan .sekisan-estimationm_inner {
  display: grid;
  place-content: center;
  grid-template-columns: minmax(auto, 53%) 1fr;
  grid-gap: 20px;
  width: 100%;
}

.pageID-original-sekisan .sekisan-estimationm_col {
  padding: 50px;
}

.pageID-original-sekisan .sekisan-estimationm_lead {
  font-size: 26px;
  text-align: center;
}

.pageID-original-sekisan .sekisan-estimationm_txt {
  margin: 0;
  font-size: 16px;
}

.pageID-original-sekisan .sekisan-estimationm_txt:not(:first-child) {
  margin-top: 1em;
}

.pageID-original-sekisan .sekisan-estimationm_btns {
  text-align: center;
}

.pageID-original-sekisan .sekisan-estimationm_btns:not(:first-child) {
  margin-top: 20px;
}

.pageID-original-sekisan .sekisan-estimationm_headLine02 {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0;
  font-size: 22px;
  font-weight: bold;
}

.pageID-original-sekisan .sekisan-estimationm_headLine02 > small {
  border: solid 1px;
  border-radius: 10px;
  padding: 2px 15px;
  font-size: 15px;
}

.pageID-original-sekisan .sekisan-estimationm_figure {
  margin: 0;
  padding: 20px 0;
}

.pageID-original-sekisan .sekisan-estimationm_partner {
  width: 100%;
  display: flex;
  font-weight: bold;
  text-align: center;
}

.pageID-original-sekisan .sekisan-estimationm_partnerCol {
  width: 50%;
  font-size: 19px;
  line-height: 1.45;
}

.pageID-original-sekisan .sekisan-estimationm_partnerCol small {
  font-size: 13px;
}

.pageID-original-sekisan .sekisan-interview_inner {
  display: grid;
  place-content: center;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.pageID-original-sekisan .sekisan-interview_inner:not(:first-child) {
  margin-top: 20px;
}

.pageID-original-sekisan .sekisan-interview_col {
  font-size: 19px;
}

.pageID-original-sekisan .sekisan-interview_figure {
  margin: 0;
  padding: 0 1em;
  text-align: center;
}

.pageID-original-sekisan .sekisan-interview_caption {
  display: grid;
  grid-template-rows: auto;
  font-size: 20px;
  font-weight: bold;
  text-align: start;
}

.pageID-original-sekisan .sekisan-interview_caption > span > small {
  margin-right: 1em;
}

.pageID-original-sekisan .sekisan-interview_pr:not(:first-child) {
  margin-top: 20px;
}

.pageID-original-sekisan .sekisan-interview_prInner {
  display: grid;
  place-content: center;
  grid-template-columns: minmax(auto, 18%) 1fr;
  grid-gap: 10px;
  padding: 0 1em;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
}

.pageID-original-sekisan .sekisan-interview_prIconCol {
  line-height: 1.45;
}

.pageID-original-sekisan .sekisan-interview_prIcon {
  display: inline-block;
  border: solid 1px;
  border-radius: 10px;
  padding: 5px 15px;
  font-size: 19px;
  text-align: left;
  margin-top: 5px;
}

.pageID-original-sekisan .sekisan-interview_prTextCol {
  text-align: start;
}

.pageID-original-sekisan .sekisan-interview_prTitle {
  font-size: 28px;
  line-height: 1.45;
}

.pageID-original-sekisan .sekisan-interview_btns {
  text-align: center;
}

.pageID-original-sekisan .sekisan-interview_btns:not(:first-child) {
  margin-top: 30px;
}

.pageID-original-sekisan .sekisan-sponsor_headLine {
  padding-top: 1.2em;
  font-size: 19px;
  width: 100%;
  border-top: solid #b8d7da 1px;
}

@media screen and (max-width: 736px) {
  .pageID-original-sekisan {
    margin-top: 10px;
    line-height: 1.65;
    /*
		.sekisan-headLine
		-------------------------------------- */
    /*
		.sekisan-btn
		<a class="sekisan-btn" href="xxxxxx">
			<span>詳しくみる　●</span>
		</a>
		-------------------------------------- */
  }
  .pageID-original-sekisan .sekisan-headLine {
    gap: 1em;
  }
  .pageID-original-sekisan .sekisan-headLine_header {
    display: flex;
    align-items: center;
    gap: 1em;
  }
  .pageID-original-sekisan .sekisan-headLine_header > * {
    margin: 0;
  }
  .pageID-original-sekisan .sekisan-headLine_icon {
    max-width: 30px;
  }
  .pageID-original-sekisan .sekisan-headLine_sub {
    padding: 5px 10px 3px;
    font-size: 17px;
    border-radius: 5px;
  }
  .pageID-original-sekisan .sekisan-headLine_title {
    font-size: 20px;
  }
  .pageID-original-sekisan .sekisan-headLine_side {
    text-align: right;
  }
  .pageID-original-sekisan .sekisan-headLine-small {
    gap: .8em;
  }
  .pageID-original-sekisan .sekisan-headLine-small .sekisan-headLine_header {
    gap: .8em;
  }
  .pageID-original-sekisan .sekisan-headLine-small .sekisan-headLine_title {
    font-size: 16px;
  }
  .pageID-original-sekisan .sekisan-btn {
    display: inline-block;
    width: fit-content;
    padding: 6px 22px;
    font-size: 13px;
  }
  .pageID-original-sekisan .sekisan-btn-small {
    padding: 3px 10px;
    font-size: 11px;
  }
  .pageID-original-sekisan .sekisan-mainVisual:not(:first-child) {
    margin-top: 15px;
  }
  .pageID-original-sekisan .sekisan-mainVisual_contents {
    width: 85%;
  }
  .pageID-original-sekisan .sekisan-mainVisual_headLine {
    font-size: 20px;
  }
  .pageID-original-sekisan .sekisan-mainVisual_txt {
    font-size: 14px;
  }
  .pageID-original-sekisan .sekisan-mainVisual_lower {
    margin: 0;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .pageID-original-sekisan .sekisan-mainVisual_lower-txt {
    font-size: 14px;
  }
  .pageID-original-sekisan .sekisan-section:not(:first-child) {
    margin-top: 60px;
  }
  .pageID-original-sekisan .sekisan-contribute_inner {
    display: grid;
    grid-template-columns: auto;
  }
  .pageID-original-sekisan .sekisan-contribute_sideFrame {
    font-size: 14px;
    top: 24%;
    right: 3%;
    max-width: 44%;
  }
  .pageID-original-sekisan .sekisan-contribute_subTitle {
    font-size: 12px;
    border-radius: 5px;
  }
  .pageID-original-sekisan .sekisan-contribute_postName {
    margin: 0;
  }
  .pageID-original-sekisan .sekisan-contribute_main {
    padding: 20px 10px;
    font-size: 19px;
  }
  .pageID-original-sekisan .sekisan-contribute_headLine {
    display: grid;
  }
  .pageID-original-sekisan .sekisan-contribute_headLine small {
    font-size: 14px;
  }
  .pageID-original-sekisan .sekisan-contribute_headLine strong {
    font-size: 18px;
  }
  .pageID-original-sekisan .sekisan-contribute_btns:not(:first-child) {
    margin-top: 20px;
  }
  .pageID-original-sekisan .sekisan-symposium {
    width: 95%;
  }
  .pageID-original-sekisan .sekisan-symposium_contents:not(:first-child) {
    margin-top: 32px;
  }
  .pageID-original-sekisan .sekisan-symposium_title {
    font-size: 22px;
  }
  .pageID-original-sekisan .sekisan-symposium_txt {
    font-size: 14px;
  }
  .pageID-original-sekisan .sekisan-symposium_btns {
    padding-top: 10px;
    padding-bottom: 40px;
  }
  .pageID-original-sekisan .sekisan-symposium_toc {
    grid-template-columns: auto;
    gap: 15px;
  }
  .pageID-original-sekisan .sekisan-symposium_tocCol {
    font-size: 15px;
    gap: 20px;
  }
  .pageID-original-sekisan .sekisan-estimationm {
    padding: 20px;
  }
  .pageID-original-sekisan .sekisan-estimationm_inner {
    grid-template-columns: auto;
    grid-gap: 35px;
  }
  .pageID-original-sekisan .sekisan-estimationm_col {
    padding: 0;
  }
  .pageID-original-sekisan .sekisan-estimationm_lead {
    font-size: 18px;
  }
  .pageID-original-sekisan .sekisan-estimationm_txt {
    font-size: 12px;
  }
  .pageID-original-sekisan .sekisan-estimationm_btns {
    text-align: center;
  }
  .pageID-original-sekisan .sekisan-estimationm_btns:not(:first-child) {
    margin-top: 20px;
  }
  .pageID-original-sekisan .sekisan-estimationm_headLine02 {
    font-size: 17px;
    padding-top: 0;
  }
  .pageID-original-sekisan .sekisan-estimationm_headLine02 > small {
    border-radius: 5px;
    padding: 2px 10px;
    font-size: 12px;
  }
  .pageID-original-sekisan .sekisan-estimationm_partnerCol {
    font-size: 14px;
  }
  .pageID-original-sekisan .sekisan-estimationm_partnerCol small {
    font-size: 11px;
  }
  .pageID-original-sekisan .sekisan-interview_inner {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 55px;
  }
  .pageID-original-sekisan .sekisan-interview_col {
    font-size: 14px;
  }
  .pageID-original-sekisan .sekisan-interview_figure {
    margin: 0;
    text-align: center;
  }
  .pageID-original-sekisan .sekisan-interview_caption {
    font-size: 13px;
  }
  .pageID-original-sekisan .sekisan-interview_caption > span > small {
    font-size: 11px;
  }
  .pageID-original-sekisan .sekisan-interview_prInner {
    grid-template-columns: minmax(auto, 18%) 1fr;
    font-size: 14px;
  }
  .pageID-original-sekisan .sekisan-interview_prIcon {
    border-radius: 5px;
    font-size: 12px;
  }
  .pageID-original-sekisan .sekisan-interview_prTitle {
    font-size: 18px;
  }
  .pageID-original-sekisan .sekisan-interview_btns:not(:first-child) {
    margin-top: 20px;
  }
  .pageID-original-sekisan .sekisan-sponsor_headLine {
    padding-top: .8em;
    font-size: 12px;
  }
}

/*!
	!!!!!!!!!!!!!!!!!!!!!!!! 削除禁止 終了 !!!!!!!!!!!!!!!!!!!!!!!!
*/
/*!
 * ！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！
 *
 * ユーザー専用スタイルシート
 *
 * assets内直下の、js、styles内のファイルを編集することは厳禁なので、
 * 新規スタイル追加、及び既存スタイルに対して追記スタイルがある場合はこちらに記載をお願いいたします。
 *
 * ！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！注意！
 */
.sampleCss {
  color: red;
  font-size: 24px;
  background-color: #f0c;
}

@media screen and (max-width: 736px) {
  .sampleCss {
    font-size: 14px;
  }
}

/*
	.pageID-xxxxxxxxxxxx
	ページ名
------------------------------------------------------------------------ */
