@charset "UTF-8";

/*
	.pageID-original-sekisan
	特集：東京都の積算
------------------------------------------------------------------------ */
.pageID-original-sekisan {
	line-height: 1.85;

	/*
	.sekisan-headLine
	<div class="sekisan-headLine">
		<div class="sekisan-headLine_header">
			<figure class="sekisan-headLine_icon">
				<img src="/files/sekisan/icon.jpg" width="60" height="60">
			</figure>
			<span class="sekisan-headLine_sub">PR</span>
			<h3 class="sekisan-headLine_title">積算とは？</h3>
		</div>
		<div class="sekisan-headLine_side">
			<div class="sekisan-headLine_btns">
				<a class="sekisan-btn" href="/blog/blog_detail.php?id=512">
					<span>詳しくみる　●</span>
				</a>
			</div>
		</div>
	</div><!-- /.sekisan-headLine -->
	-------------------------------------- */
	.sekisan-headLine {
		$this: '.sekisan-headLine';
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 2.5em;
		& > * {
			margin: 0;
		}
		&_header {
			display: flex;
			align-items: center;
			gap: 2.5em;
			& > * {
				margin: 0;
			}
		}
		&_icon {
			flex: 0 0 auto;
		}
		&_sub {
			flex: 0 0 auto;
			border: solid 1px;
			border-radius: 10px;
			padding: 8px 15px 6px;
			font-size: 28px;
			font-weight: 700;
			line-height: 1.2;
		}
		&_title {
			font-size: 40px;
			font-weight: bold;
			line-height: 1.5;
		}

		&_side {
		}
		&_btns {
			text-align: right;
		}

		&-small {
			gap: 1.8em;
			#{$this}_header {
				gap: 1.8em;
			}
			#{$this}_title {
				font-size: 26px;
			}
		}
		&-underLine {
			padding-bottom: 10px;
			border-bottom: solid #000000 1px;
		}
	}

	/*
	.sekisan-btn
	<a class="sekisan-btn" href="xxxxxx">
		<span>詳しくみる　●</span>
	</a>
	-------------------------------------- */
	.sekisan-btn {
		$this: '.sekisan-btn';
		display: inline-block;
		width: fit-content;

		padding: 12px 50px;
		// padding: 10px 50px;
		text-decoration: none;
		font-size: 18px;
		// font-size: 14px;
		border: solid 1px;
		background: #ffffff;
		border-radius: calc(1px / 0);
		> span {
		}
		&:hover {
			opacity: 1;
			color: #fff;
			background-color: #253f68;
		}
		&-small {
			padding: 5px 25px 3px;
			font-size: 14px;
			// font-size: 12px;
		}
	}

	.sekisan-mainVisual {
		$this: '.sekisan-mainVisual';

		background: #b8d7da;
		&_mv {
			text-align: center;
		}
		&_contents {
			width: 75%;
			margin: 20px auto 0;
		}
		&_headLine {
			font-size: 40px;
			font-weight: bold;
			border-bottom: solid 1px;
		}
		&_txt {
			font-size: 19px;
			margin-bottom: 18px;
		}
		&_lower {
			margin: 0;
			padding-top: 100px;
			padding-bottom: 100px;
		}
		&_lower-txt {
			font-size: 19px;
		}
	}

	.sekisan-section {
		$this: '.sekisan-section';
		&:not(:first-child) {
			margin-top: 100px;
		}
	}

	.sekisan-contribute {
		$this: '.sekisan-contribute';

		background: #93959d;
		&_inner {
			// display: flex;
			display: grid;
			place-content: center;
			grid-template-columns: minmax(auto, 460px) 1fr;
		}
		&_side {
			position: relative;
		}
		&_figure {
			margin: 0;
		}
		&_sideFrame {
			display: inline-block;
			position: absolute;
			text-align: center;
			font-size: 19px;
			font-weight: bold;
			top: 18%;
			right: 5%;
		}
		&_subTitle {
			border: solid 1px;
			border-radius: 10px;
			padding: 5px 15px;
		}
		&_postName {
			margin: 0;
		}
		&_main {
			align-self: center;
			margin: 0 auto;
			text-align: center;
			font-size: 19px;
			color: #ffffff;
		}
		&_headLine {
			display: grid;
			grid-template-rows: auto;
			gap: 5px;
			margin: 0;
			line-height: 1.45;
			small {
				font-size: 19px;
			}
			strong {
				font-size: 36px;
				font-weight: bold;
			}
		}
		&_btns {
			margin: 0;
			&:not(:first-child) {
				margin-top: 18px;
			}
		}
	}

	.sekisan-symposium {
		$this: '.sekisan-symposium';
		width: 75%;
		margin: 0 auto;

		&_contents {
			&:not(:first-child) {
				margin-top: 38px;
			}
		}
		&_figure {
			text-align: center;
		}
		&_title {
			font-size: 40px;
			text-align: center;
			&:not(:first-child) {
				margin-top: 20px;
			}
		}
		&_txt {
			font-size: 19px;
			margin-bottom: 20px;
		}
		&_btns {
			text-align: center;
			padding-top: 40px;
			padding-bottom: 60px;
		}

		&_toc {
			display: grid;
			place-content: center;
			justify-items: center;
			grid-template-columns: repeat(2, 1fr);
			gap: 20px;
			width: 100%;
		}

		&_tocCol {
			font-size: 26px;
			display: flex;
			flex-wrap: wrap;
			gap: 20px;
			align-items: center;
			font-weight: bold;
		}
	}

	.sekisan-estimationm {
		$this: '.sekisan-estimationm';
		background: #b8d7da;
		&_inner {
			display: grid;
			place-content: center;
			grid-template-columns: minmax(auto, 53%) 1fr;
			grid-gap: 20px;
			width: 100%;
			// display: flex;
		}

		&_col {
			padding: 50px;
			&-left {
			}
			&-right {
			}
		}

		&_lead {
			font-size: 26px;
			text-align: center;
		}

		&_txt {
			margin: 0;
			font-size: 16px;
			&:not(:first-child) {
				margin-top: 1em;
			}
		}

		&_btns {
			text-align: center;
			&:not(:first-child) {
				margin-top: 20px;
			}
		}

		&_headLine02 {
			display: flex;
			align-items: center;
			gap: 10px;
			margin: 0;
			font-size: 22px;
			font-weight: bold;
			// padding-top: 50px;
			> small {
				border: solid 1px;
				border-radius: 10px;
				padding: 2px 15px;
				font-size: 15px;
			}
		}

		&_figure {
			margin: 0;
			padding: 20px 0;
		}

		&_partner {
			width: 100%;
			display: flex;
			font-weight: bold;
			text-align: center;
		}

		&_partnerCol {
			width: 50%;
			font-size: 19px;
			line-height: 1.45;
		}

		&_partnerCol small {
			font-size: 13px;
		}
	}

	.sekisan-interview {
		$this: '.sekisan-interview';
		&_inner {
			display: grid;
			place-content: center;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 20px;
			&:not(:first-child) {
				margin-top: 20px;
			}
		}
		&_col {
			font-size: 19px;
		}

		&_figure {
			margin: 0;
			padding: 0 1em;
			text-align: center;
		}

		&_caption {
			display: grid;
			grid-template-rows: auto;
			font-size: 20px;
			font-weight: bold;
			text-align: start;
			> span > small {
				margin-right: 1em;
			}
		}

		&_pr {
			&:not(:first-child) {
				margin-top: 20px;
			}
		}

		&_prInner {
			display: grid;
			place-content: center;
			grid-template-columns: minmax(auto, 18%) 1fr;
			grid-gap: 10px;

			padding: 0 1em;
			width: 100%;
			font-size: 20px;
			font-weight: bold;
		}

		&_prIconCol {
			line-height: 1.45;
		}

		&_prIcon {
			display: inline-block;
			border: solid 1px;
			border-radius: 10px;
			padding: 5px 15px;
			font-size: 19px;
			text-align: left;
			margin-top: 5px;
		}

		&_prTextCol {
			text-align: start;
		}

		&_prTitle {
			font-size: 28px;
			line-height: 1.45;
		}

		&_btns {
			text-align: center;
			&:not(:first-child) {
				margin-top: 30px;
			}
		}
	}

	.sekisan-sponsor {
		$this: '.sekisan-sponsor';
		&_headLine {
			padding-top: 1.2em;
			font-size: 19px;
			width: 100%;
			border-top: solid #b8d7da 1px;
		}
	}

}

@media screen and (max-width: 735px) {
}
@media screen and (max-width: 735px + 1){
	.pageID-original-sekisan {

		margin-top: 10px;
		line-height: 1.65;

		/*
		.sekisan-headLine
		-------------------------------------- */
		.sekisan-headLine {
			$this: '.sekisan-headLine';
			// display: grid;
			// grid-template-rows: auto;
			gap: 1em;
			&_header {
				display: flex;
				align-items: center;
				gap: 1em;
				& > * {
					margin: 0;
				}
			}
			&_icon {
				max-width: 30px;
			}
			&_sub {
				padding: 5px 10px 3px;
				font-size: 17px;
				border-radius: 5px;
			}
			&_title {
				font-size: 20px;
			}
			&_side {
				text-align: right;
			}

			&-small {
				gap: .8em;
				#{$this}_header {
					gap: .8em;
				}
				#{$this}_title {
					font-size: 16px;
				}
			}
		}

		/*
		.sekisan-btn
		<a class="sekisan-btn" href="xxxxxx">
			<span>詳しくみる　●</span>
		</a>
		-------------------------------------- */
		.sekisan-btn {
			$this: '.sekisan-btn';
			display: inline-block;
			width: fit-content;

			padding: 6px 22px;
			font-size: 13px;
			&-small {
				padding: 3px 10px;
				font-size: 11px;
			}
		}

		.sekisan-mainVisual {
			$this: '.sekisan-mainVisual';

			&:not(:first-child) {
				margin-top: 15px;
			}
			&_contents {
				width: 85%;
			}
			&_headLine {
				font-size: 20px;
			}
			&_txt {
				font-size: 14px;
			}
			&_lower {
				margin: 0;
				padding-top: 50px;
				padding-bottom: 50px;
			}
			&_lower-txt {
				font-size: 14px;
			}
		}

		.sekisan-section {
			$this: '.sekisan-section';
			&:not(:first-child) {
				margin-top: 60px;
			}
		}

		.sekisan-contribute {
			$this: '.sekisan-contribute';

			&_inner {
				display: grid;
				grid-template-columns: auto;
			}
			&_sideFrame {
				font-size: 14px;
				top: 24%;
				right: 3%;
				max-width: 44%;
			}
			&_subTitle {
				font-size: 12px;
				border-radius: 5px;
			}
			&_postName {
				margin: 0;
			}
			&_main {
				padding: 20px 10px;
				font-size: 19px;
			}
			&_headLine {
				display: grid;
				small {
					font-size: 14px;
				}
				strong {
					font-size: 18px;
				}
			}
			&_btns {
				&:not(:first-child) {
					margin-top: 20px;
				}
			}
		}

		.sekisan-symposium {
			$this: '.sekisan-symposium';
			width: 95%;

			&_contents {
				&:not(:first-child) {
					margin-top: 32px;
				}
			}
			&_title {
				font-size: 22px;
			}
			&_txt {
				font-size: 14px;
			}
			&_btns {
				padding-top: 10px;
				padding-bottom: 40px;
			}

			&_toc {
				grid-template-columns: auto;
				gap: 15px;
			}

			&_tocCol {
				font-size: 15px;
				gap: 20px;
			}
		}

		.sekisan-estimationm {
			$this: '.sekisan-estimationm';

			padding: 20px;
			&_inner {
				grid-template-columns: auto;
				grid-gap: 35px;
			}

			&_col {
				padding: 0;
			}

			&_lead {
				font-size: 18px;
			}

			&_txt {
				font-size: 12px;
			}

			&_btns {
				text-align: center;
				&:not(:first-child) {
					margin-top: 20px;
				}
			}

			&_headLine02 {
				font-size: 17px;
				padding-top: 0;
				> small {
					border-radius: 5px;
					padding: 2px 10px;
					font-size: 12px;
				}
			}

			&_partnerCol {
				font-size: 14px;
			}

			&_partnerCol small {
				font-size: 11px;
			}
		}

		.sekisan-interview {
			$this: '.sekisan-interview';
			&_inner {
				display: grid;
				grid-template-columns: auto;
				grid-gap: 55px;
			}
			&_col {
				font-size: 14px;
			}

			&_figure {
				margin: 0;
				text-align: center;
			}

			&_caption {
				font-size: 13px;
				> span > small {
					font-size: 11px;
				}
			}

			&_prInner {
				grid-template-columns: minmax(auto, 18%) 1fr;
				font-size: 14px;
			}

			&_prIcon {
				border-radius: 5px;
				font-size: 12px;
			}

			&_prTitle {
				font-size: 18px;
			}

			&_btns {
				&:not(:first-child) {
					margin-top: 20px;
				}
			}
		}

		.sekisan-sponsor {
			$this: '.sekisan-sponsor';
			&_headLine {
				padding-top: .8em;
				font-size: 12px;
			}
		}
	}
}
